/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
import BigNumber from 'bignumber.js'
import masterchefABI from 'config/abi/masterchef.json'
import dualFarmABI from 'config/abi/dualFarm.json'
import dualRewarderABI from 'config/abi/dualRewarder.json'
import erc20 from 'config/abi/erc20.json'
import { getAddress, getMasterChefAddress } from 'utils/addressHelpers'
import { BIG_TEN, BIG_ZERO } from 'utils/bigNumber'
import multicall from 'utils/multicall'
import { SerializedFarm, SerializedBigNumber } from '../types'

type PublicFarmData = {
  rewardPrice?: number
  rewardPerSecond?: SerializedBigNumber
  rewardStartTimestamp?: SerializedBigNumber
  rewardEndTimestamp?: SerializedBigNumber
  tokenAmountTotal: SerializedBigNumber
  lpTotalInQuoteToken: SerializedBigNumber
  quoteTokenAmountMc: SerializedBigNumber
  lpTotalSupply: SerializedBigNumber
  tokenPriceVsQuote: SerializedBigNumber
  poolWeight: SerializedBigNumber
  multiplier: string
  lpTokenBalanceMC: SerializedBigNumber
}

const fetch3MMData = async (farm: SerializedFarm): Promise<PublicFarmData> => {
  const { pid, lpAddresses, token, quoteToken, basePool, dualMasterchef } = farm

  const lpAddress = getAddress(lpAddresses)
  const calls = [
    // Balance of token in the LP contract
    {
      address: token.address,
      name: 'balanceOf',
      params: [lpAddress],
    },
    // Balance of quote token on LP contract
    {
      address: quoteToken.address,
      name: 'balanceOf',
      params: [lpAddress],
    },
    // Balance of LP tokens aka 3MM token in the master chef contract
    {
      address: token.address,
      name: 'balanceOf',
      params: [getMasterChefAddress()],
    },
    // Total supply of LP aka 3MM token tokens
    {
      address: token.address,
      name: 'totalSupply',
    },
    // Token decimals
    {
      address: token.address,
      name: 'decimals',
    },
    // Quote token decimals
    {
      address: quoteToken.address,
      name: 'decimals',
    },
    // Underlying price
    {
      address: basePool,
      name: 'getVirtualPrice',
    },
  ]

  const [
    tokenBalanceLP,
    quoteTokenBalanceLP,
    lpTokenBalanceMC,
    lpTotalSupply,
    tokenDecimals,
    quoteTokenDecimals,
    virtualPrice,
  ] = await multicall(erc20, calls)

  // Ratio in % of LP tokens that are staked in the MC, vs the total number in circulation
  const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))
  // const lpTokenRatio = new BigNumber(1000000).div(new BigNumber(1000)) // FIXME debug hard code for farms

  // Raw amount of token in the LP, including those not staked
  const tokenAmountTotal = new BigNumber(tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals))
  const quoteTokenAmountTotal = new BigNumber(quoteTokenBalanceLP).div(BIG_TEN.pow(quoteTokenDecimals))
  // const tokenAmountTotal = new BigNumber(token.symbol === 'WORKBENCH' ? new BigNumber(10000000000000000000000000) : tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals)) // FIXME debug hard code for farms
  // const quoteTokenAmountTotal = new BigNumber(quoteToken.symbol === 'WMATIC' ? new BigNumber(1000000000000000000) : quoteTokenBalanceLP).div(BIG_TEN.pow(quoteTokenDecimals)) // FIXME debug hard code for farms
  // Amount of quoteToken in the LP that are staked in the MC
  const quoteTokenAmountMc = quoteTokenAmountTotal.times(lpTokenRatio)

  // Total staked in LP, in quote token value
  const lpTotalInQuoteToken = new BigNumber(lpTokenBalanceMC).div(BIG_TEN.pow(18))

  // Only make masterchef calls if farm has pid
  const [info, totalAllocPoint] =
    pid || pid === 0
      ? await multicall(masterchefABI, [
          {
            address: getMasterChefAddress(),
            name: 'poolInfo',
            params: [pid],
          },
          {
            address: getMasterChefAddress(),
            name: 'totalAllocPoint',
          },
        ])
      : [null, null]

  const allocPoint = info ? new BigNumber(info.allocPoint?._hex) : BIG_ZERO
  const poolWeight = totalAllocPoint ? allocPoint.div(new BigNumber(totalAllocPoint)) : BIG_ZERO

  if (dualMasterchef) {
    const calls2 = [
      {
        address: farm.rewarder,
        name: 'rewardPerSecond',
        params: [],
      },
      {
        address: farm.rewarder,
        name: 'rewardStartTimestamp',
        params: [],
      },
      {
        address: farm.rewarder,
        name: 'rewardEndTimestamp',
        params: [],
      },
    ]

    const [_rewardPerSecond, rewardStartTimestamp, rewardEndTimestamp] = await multicall(dualRewarderABI, calls2)
    const response = await fetch(`https://api.dexscreener.com/latest/dex/tokens/${farm.dualToken.address}`)
    const data = await response.json()

    return {
      // TODO: No longer emitting dual token rewards, hence setting it to 0
      rewardPerSecond: new BigNumber(0).toJSON(),
      // rewardPerSecond: new BigNumber(rewardPerSecond).toJSON(),
      rewardStartTimestamp: new BigNumber(rewardStartTimestamp?.[0]?.toString()).toJSON(),
      rewardEndTimestamp: new BigNumber(rewardEndTimestamp?.[0]?.toString()).toJSON(),
      rewardPrice: Number.parseFloat(
        data.pairs
          ?.filter((a: { baseToken: { address: string } }) => a.baseToken.address === farm.dualToken.address)
          .sort((a: { liquidity: { usd: number } }, b: { liquidity: { usd: any } }) => {
            if (!a.liquidity || !b.liquidity) return 0
            return b.liquidity.usd || 0 - a.liquidity.usd || 0
          })[0].priceUsd,
      ),
      tokenAmountTotal: new BigNumber(lpTokenBalanceMC).div(BIG_TEN.pow(18)).toJSON(),
      lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
      lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
      tokenPriceVsQuote: new BigNumber(virtualPrice).div(BIG_TEN.pow(18)).toJSON(),
      poolWeight: poolWeight.toJSON(),
      multiplier: `${allocPoint.div(100).toString()}X`,
      lpTokenBalanceMC: new BigNumber(lpTokenBalanceMC).toJSON(),
      quoteTokenAmountMc: quoteTokenAmountMc.toJSON(),
    }
  }

  return {
    tokenAmountTotal: new BigNumber(lpTokenBalanceMC).div(BIG_TEN.pow(18)).toJSON(),
    lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
    lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
    tokenPriceVsQuote: new BigNumber(virtualPrice).div(BIG_TEN.pow(18)).toJSON(),
    poolWeight: poolWeight.toJSON(),
    multiplier: `${allocPoint.div(100).toString()}X`,
    lpTokenBalanceMC: new BigNumber(lpTokenBalanceMC).toJSON(),
    quoteTokenAmountMc: quoteTokenAmountMc.toJSON(),
  }
}

const fetchMLPData = async (farm: SerializedFarm): Promise<PublicFarmData> => {
  const { pid, lpAddresses, token, quoteToken, dualMasterchef } = farm

  const lpAddress = getAddress(lpAddresses)
  const calls = [
    // Balance of token in the LP contract
    {
      address: token.address,
      name: 'balanceOf',
      params: [lpAddress],
    },
    // Balance of quote token on LP contract
    {
      address: quoteToken.address,
      name: 'balanceOf',
      params: [lpAddress],
    },
    // Balance of LP tokens aka 3MM token in the master chef contract
    {
      address: token.address,
      name: 'balanceOf',
      params: [getMasterChefAddress()],
    },
    // Total supply of LP aka 3MM token tokens
    {
      address: token.address,
      name: 'totalSupply',
    },
    // Token decimals
    {
      address: token.address,
      name: 'decimals',
    },
    // Quote token decimals
    {
      address: quoteToken.address,
      name: 'decimals',
    },
    {
      address: token.address,
      name: 'balanceOf',
      params: [getMasterChefAddress()],
    },
  ]

  const [
    tokenBalanceLP,
    quoteTokenBalanceLP,
    lpTokenBalanceMC,
    lpTotalSupply,
    tokenDecimals,
    quoteTokenDecimals,
    tokenBalance,
  ] = await multicall(erc20, calls)

  // Ratio in % of LP tokens that are staked in the MC, vs the total number in circulation
  const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))
  // const lpTokenRatio = new BigNumber(1000000).div(new BigNumber(1000)) // FIXME debug hard code for farms

  // Raw amount of token in the LP, including those not staked
  const tokenAmountTotal = new BigNumber(tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals))
  const quoteTokenAmountTotal = new BigNumber(quoteTokenBalanceLP).div(BIG_TEN.pow(quoteTokenDecimals))
  // const tokenAmountTotal = new BigNumber(token.symbol === 'WORKBENCH' ? new BigNumber(10000000000000000000000000) : tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals)) // FIXME debug hard code for farms
  // const quoteTokenAmountTotal = new BigNumber(quoteToken.symbol === 'WMATIC' ? new BigNumber(1000000000000000000) : quoteTokenBalanceLP).div(BIG_TEN.pow(quoteTokenDecimals)) // FIXME debug hard code for farms
  // Amount of quoteToken in the LP that are staked in the MC
  const quoteTokenAmountMc = quoteTokenAmountTotal.times(lpTokenRatio)

  // Total staked in LP, in quote token value
  const lpTotalInQuoteToken = new BigNumber(tokenBalance).div(BIG_TEN.pow(18))

  // Only make masterchef calls if farm has pid
  const [info, totalAllocPoint] =
    pid || pid === 0
      ? await multicall(masterchefABI, [
          {
            address: getMasterChefAddress(),
            name: 'poolInfo',
            params: [pid],
          },
          {
            address: getMasterChefAddress(),
            name: 'totalAllocPoint',
          },
        ])
      : [null, null]

  const allocPoint = info ? new BigNumber(info.allocPoint?._hex) : BIG_ZERO
  const poolWeight = totalAllocPoint ? allocPoint.div(new BigNumber(totalAllocPoint)) : BIG_ZERO

  return {
    tokenAmountTotal: new BigNumber(lpTokenBalanceMC).div(BIG_TEN.pow(18)).toJSON(),
    lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
    lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
    tokenPriceVsQuote: quoteTokenAmountTotal.div(tokenAmountTotal).toJSON(),
    poolWeight: poolWeight.toJSON(),
    multiplier: `${allocPoint.div(100).toString()}X`,
    lpTokenBalanceMC: new BigNumber(lpTokenBalanceMC).toJSON(),
    quoteTokenAmountMc: quoteTokenAmountMc.toJSON(),
  }
}

const fetchFarm = async (farm: SerializedFarm): Promise<PublicFarmData> => {
  const { pid, lpAddresses, token, quoteToken, isStableSwap, dualMasterchef, dualToken } = farm

  if (farm.pid === 13) return fetchMLPData(farm)

  if (!isStableSwap) {
    const lpAddress = getAddress(lpAddresses)
    const calls = [
      // Balance of token in the LP contract
      {
        address: token.address,
        name: 'balanceOf',
        params: [lpAddress],
      },
      // Balance of quote token on LP contract
      {
        address: quoteToken.address,
        name: 'balanceOf',
        params: [lpAddress],
      },
      // Balance of LP tokens in the master chef contract
      {
        address: lpAddress,
        name: 'balanceOf',
        params: [getMasterChefAddress()],
      },
      // Total supply of LP tokens
      {
        address: lpAddress,
        name: 'totalSupply',
      },
      // Token decimals
      {
        address: token.address,
        name: 'decimals',
      },
      // Quote token decimals
      {
        address: quoteToken.address,
        name: 'decimals',
      },
    ]

    const [tokenBalanceLP, quoteTokenBalanceLP, lpTokenBalanceMC, lpTotalSupply, tokenDecimals, quoteTokenDecimals] =
      await multicall(erc20, calls)

    if (pid === undefined) {
      debugger
    }
    // Ratio in % of LP tokens that are staked in the MC, vs the total number in circulation
    const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))
    // const lpTokenRatio = new BigNumber(1000000).div(new BigNumber(1000)) // FIXME debug hard code for farms

    // Raw amount of token in the LP, including those not staked
    const tokenAmountTotal = new BigNumber(tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals))
    const quoteTokenAmountTotal = new BigNumber(quoteTokenBalanceLP).div(BIG_TEN.pow(quoteTokenDecimals))
    // const tokenAmountTotal = new BigNumber(token.symbol === 'WORKBENCH' ? new BigNumber(10000000000000000000000000) : tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals)) // FIXME debug hard code for farms
    // const quoteTokenAmountTotal = new BigNumber(quoteToken.symbol === 'WMATIC' ? new BigNumber(1000000000000000000) : quoteTokenBalanceLP).div(BIG_TEN.pow(quoteTokenDecimals)) // FIXME debug hard code for farms
    // Amount of quoteToken in the LP that are staked in the MC
    const quoteTokenAmountMc = quoteTokenAmountTotal.times(lpTokenRatio)

    // Total staked in LP, in quote token value
    const lpTotalInQuoteToken = quoteTokenAmountMc.times(new BigNumber(2))

    // Only make masterchef calls if farm has pid
    const [info, totalAllocPoint] =
      pid || pid === 0
        ? await multicall(masterchefABI, [
            {
              address: getMasterChefAddress(),
              name: 'poolInfo',
              params: [pid],
            },
            {
              address: getMasterChefAddress(),
              name: 'totalAllocPoint',
            },
          ])
        : [null, null]

    const allocPoint = info ? new BigNumber(info.allocPoint?._hex) : BIG_ZERO
    const poolWeight = totalAllocPoint ? allocPoint.div(new BigNumber(totalAllocPoint)) : BIG_ZERO

    if (dualMasterchef) {
      const calls2 = [
        {
          address: farm.rewarder,
          name: 'rewardPerSecond',
          params: [],
        },
        {
          address: farm.rewarder,
          name: 'rewardStartTimestamp',
          params: [],
        },
        {
          address: farm.rewarder,
          name: 'rewardEndTimestamp',
          params: [],
        },
      ]

      const [_rewardPerSecond, rewardStartTimestamp, rewardEndTimestamp] = await multicall(dualRewarderABI, calls2)
      const response = await fetch(`https://api.dexscreener.com/latest/dex/tokens/${farm.dualToken.address}`)
      const data = await response.json()

      return {
        // TODO: No longer emitting dual token rewards, hence setting it to 0
        rewardPerSecond: new BigNumber(0).toJSON(),
        // rewardPerSecond: new BigNumber(rewardPerSecond).toJSON(),
        rewardStartTimestamp: new BigNumber(rewardStartTimestamp).toJSON(),
        rewardEndTimestamp: new BigNumber(rewardEndTimestamp).toJSON(),
        rewardPrice: Number.parseFloat(
          data.pairs
            ?.filter((a: { baseToken: { address: string } }) => a.baseToken.address === farm.dualToken.address)
            .sort((a: { liquidity: { usd: number } }, b: { liquidity: { usd: any } }) => {
              if (!a.liquidity || !b.liquidity) return 0
              return b.liquidity.usd || 0 - a.liquidity.usd || 0
            })[0].priceUsd,
        ),
        tokenAmountTotal: tokenAmountTotal.toJSON(),
        lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        tokenPriceVsQuote: quoteTokenAmountTotal.div(tokenAmountTotal).toJSON(),
        poolWeight: poolWeight.toJSON(),
        multiplier: `${allocPoint.div(100).toString()}X`,
        lpTokenBalanceMC: new BigNumber(lpTokenBalanceMC).toJSON(),
        quoteTokenAmountMc: quoteTokenAmountMc.toJSON(),
      }
    }

    // if (pid === 1) {
    //   console.log({
    //     tokenAmountTotal: tokenAmountTotal.toJSON(),
    //     lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
    //     lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
    //     tokenPriceVsQuote: quoteTokenAmountTotal.div(tokenAmountTotal).toJSON(),
    //     poolWeight: poolWeight.toJSON(),
    //     multiplier: `${allocPoint.div(100).toString()}X`,
    //     lpTokenBalanceMC: new BigNumber(lpTokenBalanceMC).toJSON(),
    //     quoteTokenAmountMc: quoteTokenAmountMc.toJSON(),
    //   })
    // }
    return {
      tokenAmountTotal: tokenAmountTotal.toJSON(),
      lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
      lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
      tokenPriceVsQuote: quoteTokenAmountTotal.div(tokenAmountTotal).toJSON(),
      poolWeight: poolWeight.toJSON(),
      multiplier: `${allocPoint.div(100).toString()}X`,
      lpTokenBalanceMC: new BigNumber(lpTokenBalanceMC).toJSON(),
      quoteTokenAmountMc: quoteTokenAmountMc.toJSON(),
    }
  }

  return fetch3MMData(farm)
}

export default fetchFarm

/* eslint-disable no-debugger */
import { ChainId } from '@polymmfinance/sdk'
import BigNumber from 'bignumber.js'
import { useFoundOnInactiveList } from 'hooks/Tokens'
import { deserializeFarm } from 'state/farms/hooks'
import { SerializedFarm, DeserializedPool, SerializedPool } from 'state/types'
import { deserializeToken } from 'state/user/hooks/helpers'
import { BIG_ZERO } from 'utils/bigNumber'
import { getBalanceAmount } from 'utils/formatBalance'

type UserData =
  | DeserializedPool['userData']
  | {
      allowance: number | string
      stakingTokenBalance: number | string
      stakedBalance: number | string
      pendingReward: number | string
    }

export const transformUserData = (userData: UserData) => {
  return {
    allowance: userData ? new BigNumber(userData.allowance) : BIG_ZERO,
    stakingTokenBalance: userData ? new BigNumber(userData.stakingTokenBalance) : BIG_ZERO,
    stakedBalance: userData ? new BigNumber(userData.stakedBalance) : BIG_ZERO,
    pendingReward: userData ? new BigNumber(userData.pendingReward) : BIG_ZERO,
  }
}

export const transformPool = (pool: SerializedPool): DeserializedPool => {
  const { totalStaked, stakingLimit, userData, stakingToken, earningToken, ...rest } = pool

  return {
    ...rest,
    stakingToken: deserializeToken(stakingToken),
    earningToken: deserializeToken(earningToken),
    userData: transformUserData(userData),
    totalStaked: new BigNumber(totalStaked),
    stakingLimit: new BigNumber(stakingLimit),
  }
}

export const getTokenPricesFromFarm = (farms: SerializedFarm[]) => {
  return farms.reduce((prices, farm) => {
    const quoteTokenAddress = farm.quoteToken.address.toLocaleLowerCase()
    const tokenAddress = farm.token.address.toLocaleLowerCase()
    /* eslint-disable no-param-reassign */
    if (!prices[quoteTokenAddress]) {
      prices[quoteTokenAddress] = new BigNumber(farm.quoteTokenPriceBusd).toNumber()
    }
    if (!prices[tokenAddress]) {
      prices[tokenAddress] = new BigNumber(farm.tokenPriceBusd).toNumber()
    }
    /* eslint-enable no-param-reassign */
    return prices
  }, {})
}

export const getLpPricesFromFarm = (farms: SerializedFarm[]) => {
  return farms.reduce((prices, farm) => {
    const dfarm = deserializeFarm(farm)

    const farmTokenPriceInUsd = new BigNumber(farm.tokenPriceBusd)
    let lpTokenPrice = BIG_ZERO

    if (dfarm.lpTotalSupply.gt(0) && dfarm.lpTotalInQuoteToken.gt(0)) {
      // Total value of base token in LP
      const valueOfBaseTokenInFarm = farmTokenPriceInUsd.times(dfarm.tokenAmountTotal)
      // Double it to get overall value in LP
      const overallValueOfAllTokensInFarm = valueOfBaseTokenInFarm.times(2)
      // Divide total value of all tokens, by the number of LP tokens
      const totalLpTokens = getBalanceAmount(dfarm.lpTotalSupply)
      lpTokenPrice = overallValueOfAllTokensInFarm.div(totalLpTokens)
      // eslint-disable-next-line no-param-reassign
      prices[farm.lpAddresses[ChainId.MAINNET].toLocaleLowerCase()] = lpTokenPrice.toNumber()
    }
    return prices
  }, {})
}

function sliceIntoChunks(arr, chunkSize) {
  const res = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize)
    res.push(chunk)
  }
  return res
}

const tokenPriceMap = {}
export const TokenPriceFromAPI = async (mmfPrice, croPrice, metfPrice) => {
  let lastUpdated = Date.now()

  // kept lowercase explicitly
  const tokenMaps = {
    // '0x8ab47799cb0d49aeb9e3a47c369813a3a3236790': 'random', // MMF-usdc.
    '0x22a31bD4cB694433B6de19e0aCC2899E553e9481': 'random',
    '0x095bc617b36ab227a379550633dfdcbf43f236f6': 'random',
    '0x94b62040d663fa870d399e2fd044d40fbc0b79ec': 'random',
  }

  if (Object.keys(tokenPriceMap).length > 0 && Date.now() < lastUpdated + 0.5 * 60 * 1000) {
    // console.log(tokenPriceMap)
    return tokenPriceMap
  }
  // const prices = {}
  const allTokens = Object.keys(tokenMaps)
  const subArrayAllTokens = sliceIntoChunks(allTokens, Math.ceil(allTokens.length / 5))

  const data = await Promise.all(
    subArrayAllTokens.map((x) => fetch(`https://api.dexscreener.com/latest/dex/tokens/${x.join(',')}`)),
  )

  const responses = (await Promise.all(data.map((r) => r.json()))).map((x) => x.pairs).flat()

  responses.forEach((cur) => {
    if (!tokenPriceMap[cur.baseToken.address.toLocaleLowerCase()]) {
      tokenPriceMap[cur.baseToken.address.toLocaleLowerCase()] = new BigNumber(cur.priceUsd).toNumber()
    }
    // console.log(cur.baseToken.address.toLocaleLowerCase(), cur.priceUsd)
  })

  // tokenPriceMap["0x1066c6753ffaf8540f691643a6d683e23599c4ab"] = tokenPriceMap["0x3a1138075bd97a33f23a87824b811146fa44288e"] // wsMino
  // tokenPriceMap["0xb966b5d6a0fcd5b373b180bbe072bbfbbee10552"] = tokenPriceMap["0x47a9d630dc5b28f75d3af3be3aaa982512cd89aa"] // xARGO
  lastUpdated = Date.now()

  return tokenPriceMap
}

// return fetch('https://api.polymm.finance/api/pairs')
//   .then((res) => {
//     return res.json()
//   })
//   .then((x) => {
//     // const { data, error } = x
//     const data = x
//     // if (error) throw new Error('API failed')
//     const prices = {
//     }

//     Object.keys(tokenMaps).forEach((token) => {
//       // LP
//       // debugger
//       const lpData = data[tokenMaps[token]]
//       if (!lpData) return

//       if (lpData && token === lpData.pair_address.toLocaleLowerCase()) {
//         prices[token] = new BigNumber(lpData.price).toNumber()
//         return
//       }

//       // base adderss
//       if (token === lpData.base_address.toLocaleLowerCase()) {
//         // Staking LP
//         if (token === '0x3a1138075bd97a33f23a87824b811146fa44288e') {
//           prices['0x1066c6753ffaf8540f691643a6d683e23599c4ab'] = new BigNumber(
//             lpData.price,
//           ).toNumber()
//         }

//         // BISON-MATIC
//         if (token === "0x3405a1bd46b85c5c029483fbecf2f3e611026e45") {
//           prices[token] = new BigNumber(croPrice).multipliedBy(new BigNumber(
//             lpData.price,
//           )).toNumber()

//           return
//         }

//         if (token === "0x654bac3ec77d6db497892478f854cf6e8245dca9") {
//           prices[token] = new BigNumber(mmfPrice).multipliedBy(new BigNumber(
//             lpData.price,
//           )).toNumber()

//           return
//         }

//         prices[token] = new BigNumber(lpData.price).toNumber()
//       }
//       // quote address
//       else {
//         if (token === '0xa0c3c184493f2fae7d2f2bd83f195a1c300fa353' || token === "0x3405a1bd46b85c5c029483fbecf2f3e611026e45") {
//           prices[token] = new BigNumber(mmfPrice).dividedBy(new BigNumber(
//             lpData.price,
//           )).toNumber()

//           return
//         }

//         if (token === '0xea6d09e31c4fcfcf76f89c47629af294955a4599') {
//           console.log(metfPrice, lpData.price)
//           prices[token] = new BigNumber(lpData.price).dividedBy(new BigNumber(
//             metfPrice
//           )).toNumber()

//           return
//         }

//         // MOON-MATIC
//         if (token === "0x7d30c36f845d1dee79f852abf3a8a402fadf3b53" || token === '0xa57a7b5f8067156c2dbb06cf5e4d8acef17aee64') {
//           prices[token] = new BigNumber(croPrice).multipliedBy(new BigNumber(1 / lpData.price)).toNumber()
//           return
//         }

//         if (token === "0xc4a174ccb5fb54a6721e11e0ca961e42715023f9") {
//           prices[token] = new BigNumber(croPrice).multipliedBy(new BigNumber(1 / lpData.price)).toNumber()
//           return
//         }

//         prices[token] = new BigNumber(1 / lpData.price).toNumber()
//       }
//     })

//     tokenPriceMap = { ...tokenPriceMap, ...prices }
//     return tokenPriceMap
//   })
//   .catch((err) => {
//     console.error(err)
//     return {}
//   })

// return { allLPData, tokenPriceMap }

// return {}

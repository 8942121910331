import { ChainId } from '@polymmfinance/sdk'
import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

// MATIC-WETH
// MATIC-WBTC
// MATIC-USDC
// MATIC-USDT
// USDC-USDT

// MATICC-MATIC

// WETH - 0xe44Fd7fCb2b1581822D0c862B68222998a0c299a
// WBTC - 0x062E66477Faf219F25D27dCED647BF57C3107d52
// USDC - 0xc21223249CA28397B4B6541dfFaEcC539BfF0c59
// USDT - 0x66e428c3f67a68878562e79A0234c1F83c208770
// WMATIC - 0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270

// MATICC-MATIC 40x
// MATIC-ETH 10x
// MATIC-WBTC 10x
// MATIC-USDC 10x
// MATIC-USDT 10x
// USDC-USDT 5x

const serializedTokens = serializeTokens()

const mainnetFarms: SerializedFarmConfig[] = [
  //   /**
  //    * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
  //    */
  // {
  //   pid: 0,
  //   lpSymbol: 'MMF',
  //   lpAddresses: {
  //     137: '0xa2B417088D63400d211A4D5EB3C4C5363f834764',
  //     80001: '0xa2B417088D63400d211A4D5EB3C4C5363f834764',
  //   },
  //   token: serializedTokens.mmf,
  //   quoteToken: serializedTokens.wmatic,
  // },
  {
    pid: 1,
    nftBoost: true,
    lpSymbol: 'MMF-WMATIC LP',
    lpAddresses: {
      137: '0xD15EB8710E28C23993968e671807d572189CC86e',
      80001: '0xD15EB8710E28C23993968e671807d572189CC86e',
    },
    token: serializedTokens.wmatic,
    quoteToken: serializedTokens.mmf,
  },
  {
    pid: 2,
    nftBoost: true,
    lpSymbol: 'MMF-USDC.e LP',
    lpAddresses: {
      137: '0x8AB47799cB0d49aEB9E3a47c369813a3a3236790',
      80001: '0x8AB47799cB0d49aEB9E3a47c369813a3a3236790',
    },
    token: serializedTokens.mmf,
    quoteToken: serializedTokens.usdc,
  },
  {
    pid: 3,
    nftBoost: true,
    lpSymbol: 'WMATIC-USDC.e LP',
    lpAddresses: {
      137: '0xb965c131f1C48d89B1760860b782D2aCDF87273b',
      80001: '0x019d9479606FBDd4aCB16488e0aAE49E4684322b',
    },
    token: serializedTokens.wmatic,
    quoteToken: serializedTokens.usdc,
  },
  {
    pid: 4,
    nftBoost: true,
    lpSymbol: 'WBTC-USDC.e LP',
    lpAddresses: {
      137: '0x1aEc8fF1C39c34338327A32bBb35011432614172',
      80001: '0x5383202D48C24aAA19873366168f2Ed558a00ff0',
    },
    token: serializedTokens.wbtc,
    quoteToken: serializedTokens.usdc,
  },
  {
    pid: 5,
    nftBoost: true,
    lpSymbol: 'WETH-USDC.e LP',
    lpAddresses: {
      137: '0x8C26252B14F9b071585B81b240C4ae2176E8cD3C',
      80001: '0xa68466208F1A3Eb21650320D2520ee8eBA5ba623',
    },
    token: serializedTokens.weth,
    quoteToken: serializedTokens.usdc,
  },
  {
    pid: 6,
    nftBoost: true,
    lpSymbol: 'WMATIC-USDT LP',
    lpAddresses: {
      137: '0xa789324E64268C5385eA7678435Fa83532705B0F',
      80001: '0xEB28c926A7Afc75fcC8d6671Acd4c4A298b38419',
    },
    token: serializedTokens.wmatic,
    quoteToken: serializedTokens.usdt,
  },
  {
    pid: 7,
    nftBoost: true,
    lpSymbol: 'USDC.e-USDT LP',
    lpAddresses: {
      137: '0x384c1b95027B73a98FE31Ea5B4B7b031b9ddD724',
      80001: '0x6F186E4BEd830D13DcE638e40bA27Fd6d91BAd0B',
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.usdc,
  },
  {
    pid: 8,
    nftBoost: true,
    lpSymbol: 'MMF-MMO LP',
    lpAddresses: {
      137: '0xd6a88114DCC01f72D1B311aD6adF860724ff35d3',
      80001: '0xd6a88114DCC01f72D1B311aD6adF860724ff35d3',
    },
    token: serializedTokens.mmo,
    quoteToken: serializedTokens.mmf,
  },
  {
    pid: 9,
    nftBoost: true,
    lpSymbol: '3MM LP',
    isStableSwap: true,
    numTokens: 1, // set as this to prevent wrong liquidity
    basePool: '0x690BBaa9EDBb762542FD198763092eaB2B2A5350',
    lpAddresses: {
      137: '0x8b6828c1Bc28Ad187A4aB05f41F2AAC547d85132',
      80001: '0x8b6828c1Bc28Ad187A4aB05f41F2AAC547d85132', // LP -> base pool address
    },
    token: serializedTokens.mm3, // 3MMLP token address
    quoteToken: serializedTokens.usdc,
  },
  {
    pid: 10,
    nftBoost: false,
    lpSymbol: 'stMATIC-WMATIC LP',
    dualMasterchef: '0x799B03dF21f5027E2A0dDcFceB383B3Fd2c4Cc39',
    rewarder: '0x3e1402AC0ef78390CaC295c6713aE0B887d7749D',
    lpAddresses: {
      137: '0x0404f9B850Df6fabdE06d4C8392AFf1a4E79a47B',
      80001: '0x0404f9B850Df6fabdE06d4C8392AFf1a4E79a47B',
    },
    token: serializedTokens.stmatic,
    quoteToken: serializedTokens.wmatic,
    dualToken: serializedTokens.ldo,
  },
  {
    pid: 11,
    nftBoost: false,
    lpSymbol: 'stmaticMM LP',
    numTokens: 1, // set as this to prevent wrong liquidity
    isStableSwap: true,
    basePool: '0xc9478769adB0b093C3b5da9Cc59A22afb36C5CaD',
    dualMasterchef: '0x799B03dF21f5027E2A0dDcFceB383B3Fd2c4Cc39',
    rewarder: '0x0FaF67d16dA185b143a5946D0A1D34860A2E884c',
    lpAddresses: {
      137: '0x8C9a93e198BC02ef48E8d7AEC3c042c5b00a4Ad3',
      80001: '0x8C9a93e198BC02ef48E8d7AEC3c042c5b00a4Ad3',
    },
    token: serializedTokens.stmaticMM,
    quoteToken: serializedTokens.wmatic,
    dualToken: serializedTokens.ldo,
  },
  {
    pid: 12,
    nftBoost: false,
    lpSymbol: 'AutoS-MMF LP',
    lpAddresses: {
      137: '0x83Ff4C64A173eB5021376Ba2AdDAf706E10739f6',
      80001: '0x83Ff4C64A173eB5021376Ba2AdDAf706E10739f6',
    },
    token: serializedTokens.autos,
    quoteToken: serializedTokens.mmf,
  },
  {
    pid: 13,
    nftBoost: false,
    lpSymbol: 'MLP',
    lpAddresses: {
      137: '0xC68433B48b21D4376aA7EEEC205038557D56Fa7C',
      80001: '0xC68433B48b21D4376aA7EEEC205038557D56Fa7C',
    },
    token: serializedTokens.mlp,
    quoteToken: serializedTokens.usdc,
  },
  {
    pid: 14,
    nftBoost: false,
    lpSymbol: 'MLP-USDC.e',
    lpAddresses: {
      137: '0xC68433B48b21D4376aA7EEEC205038557D56Fa7C',
      80001: '0xC68433B48b21D4376aA7EEEC205038557D56Fa7C',
    },
    token: serializedTokens.mlp,
    quoteToken: serializedTokens.usdc,
  },
  {
    pid: 16,
    nftBoost: false,
    lpSymbol: 'DSF-MUFFIN LP',
    lpAddresses: {
      137: '0xBbCe6773705d8cda1dBdb9A046A4F1dfFbe3Cbc1',
      80001: '0xBbCe6773705d8cda1dBdb9A046A4F1dfFbe3Cbc1',
    },
    token: serializedTokens.muffin,
    quoteToken: serializedTokens.dsf,
  },
  {
    pid: 17,
    nftBoost: false,
    lpSymbol: 'MMF-MLP LP',
    lpAddresses: {
      137: '0xEADc377ed7b4f7eb26beB0a3F3CC2a574462188e',
      80001: '0xEADc377ed7b4f7eb26beB0a3F3CC2a574462188e',
    },
    token: serializedTokens.mmf,
    quoteToken: serializedTokens.mlp,
  },
  {
    pid: 18,
    nftBoost: false,
    dualMasterchef: '0x799B03dF21f5027E2A0dDcFceB383B3Fd2c4Cc39',
    rewarder: '0xb208c0E74318CA522a8a5A67D6C92BA01259fD84',
    lpSymbol: 'LDO-stMATIC LP',
    lpAddresses: {
      137: '0x0d54918038dbEE259173bdb055DCbC58211626FC',
      80001: '0x0d54918038dbEE259173bdb055DCbC58211626FC',
    },
    token: serializedTokens.ldo,
    quoteToken: serializedTokens.stmatic,
    dualToken: serializedTokens.ldo,
  },
  {
    pid: 19,
    nftBoost: false,
    lpSymbol: 'WETH-MLP LP',
    lpAddresses: {
      137: '0x5E2698B459DF67c38059228A2c34e4D9E298CEFf',
      80001: '0x5E2698B459DF67c38059228A2c34e4D9E298CEFf',
    },
    token: serializedTokens.weth,
    quoteToken: serializedTokens.mlp,
  },
  // {
  //   pid: 7,
  //   lpSymbol: 'MMF-USDC LP',
  //   lpAddresses: {
  //     137: '0x722f19bd9A1E5bA97b3020c6028c279d27E4293C',
  //     80001: '0x722f19bd9A1E5bA97b3020c6028c279d27E4293C',
  //   },
  //   token: serializedTokens.mmf,
  //   quoteToken: serializedTokens.usdc,
  // },
  // {
  //   pid: 9,
  //   lpSymbol: 'MMF-USDT LP',
  //   lpAddresses: {
  //     137: '0x5801d37e04ab1f266c35a277e06c9d3afa1c9ca2',
  //     80001: '0x5801d37e04ab1f266c35a277e06c9d3afa1c9ca2',
  //   },
  //   token: serializedTokens.mmf,
  //   quoteToken: serializedTokens.usdt,
  // },
  // {
  //   pid: 10,
  //   lpSymbol: 'MMO-MATIC LP',
  //   lpAddresses: {
  //     137: '0xf0b5074DBf73c96d766C9A48726CEe7a6074D436',
  //     80001: '0xf0b5074DBf73c96d766C9A48726CEe7a6074D436',
  //   },
  //   token: serializedTokens.mmo,
  //   quoteToken: serializedTokens.wmatic,
  // },
  // {
  //   pid: 11,
  //   lpSymbol: 'DAI-USDC LP',
  //   lpAddresses: {
  //     137: '0x787A47b0596fa8F7D6666F3C59696b3c57bB612b',
  //     80001: '0x787A47b0596fa8F7D6666F3C59696b3c57bB612b',
  //   },
  //   token: serializedTokens.dai,
  //   quoteToken: serializedTokens.usdc,
  // },
  // {
  //   pid: 12,
  //   lpSymbol: 'LIQ-MATIC LP',
  //   lpAddresses: {
  //     137: '0x081FbAE367269725af7a21479eddA39f62f4BAda',
  //     80001: '0x081FbAE367269725af7a21479eddA39f62f4BAda',
  //   },
  //   token: serializedTokens.liq,
  //   quoteToken: serializedTokens.wmatic,
  // },
  // {
  //   pid: 13,
  //   lpSymbol: 'MIMAS-MATIC LP',
  //   lpAddresses: {
  //     137: '0xf56fdfeef0ba3de23dab13a85602bd7bf135e80f',
  //     80001: '0xf56fdfeef0ba3de23dab13a85602bd7bf135e80f',
  //   },
  //   token: serializedTokens.mimas,
  //   quoteToken: serializedTokens.wmatic,
  // },
  // {
  //   pid: 14,
  //   lpSymbol: 'BISON-MATIC LP',
  //   lpAddresses: {
  //     137: '0xd3FD1eA9f86c6C6Bbdc6536a2247392D764543fD',
  //     80001: '0xd3FD1eA9f86c6C6Bbdc6536a2247392D764543fD',
  //   },
  //   token: serializedTokens.bison,
  //   quoteToken: serializedTokens.wmatic,
  // },
  // {
  //   pid: 15,
  //   lpSymbol: 'MATICISSANT-MMF LP',
  //   lpAddresses: {
  //     137: '0xde991150329dbe53389db41db459cae3ff220bac',
  //     80001: '0xde991150329dbe53389db41db459cae3ff220bac',
  //   },
  //   token: serializedTokens.croissant,
  //   quoteToken: serializedTokens.mmf,
  // },
  // {
  //   pid: 16,
  //   lpSymbol: 'CRK-WMATIC LP',
  //   lpAddresses: {
  //     137: '0xc2f62bd1416845f606c5e48181743f7128a30ee3',
  //     80001: '0xc2f62bd1416845f606c5e48181743f7128a30ee3',
  //   },
  //   token: serializedTokens.crk,
  //   quoteToken: serializedTokens.wmatic,
  // },
  // {
  //   pid: 17,
  //   lpSymbol: 'CRP-MMF LP',
  //   lpAddresses: {
  //     137: '0x1338D3C3Cc56f71B45f95F9988e762e4a1EF228D',
  //     80001: '0x1338D3C3Cc56f71B45f95F9988e762e4a1EF228D',
  //   },
  //   token: serializedTokens.crp,
  //   quoteToken: serializedTokens.mmf,
  // },
  // {
  //   pid: 18,
  //   lpSymbol: 'DNA-USDC LP',
  //   lpAddresses: {
  //     137: '0x853067186eeB57241d8D460bD8c3aA92CBF6f60e',
  //     80001: '0x853067186eeB57241d8D460bD8c3aA92CBF6f60e',
  //   },
  //   token: serializedTokens.dna,
  //   quoteToken: serializedTokens.usdc,
  // },
  // {
  //   pid: 19,
  //   lpSymbol: 'MOON-wMATIC LP',
  //   lpAddresses: {
  //     137: '0xAeFd1c8B1acC0ECCba26d5c6c712dDf4741E24C7',
  //     80001: '0xAeFd1c8B1acC0ECCba26d5c6c712dDf4741E24C7',
  //   },
  //   token: serializedTokens.moon,
  //   quoteToken: serializedTokens.wmatic,
  // },
  // {
  //   pid: 20,
  //   lpSymbol: 'ANN-MATIC LP',
  //   lpAddresses: {
  //     137: '0x3DD1617e3E8ACf086efb41f2E3b3732A381DB140',
  //     80001: '0x3DD1617e3E8ACf086efb41f2E3b3732A381DB140',
  //   },
  //   token: serializedTokens.ann,
  //   quoteToken: serializedTokens.wmatic,
  // },
  // {
  //   pid: 21,
  //   lpSymbol: 'SPHERE-MMF LP',
  //   lpAddresses: {
  //     137: '0x8ec4F97DE93B4B7BeA29EE5a1E452d1481D62BfC',
  //     80001: '0x8ec4F97DE93B4B7BeA29EE5a1E452d1481D62BfC',
  //   },
  //   token: serializedTokens.sphere,
  //   quoteToken: serializedTokens.mmf,
  // },
  // {
  //   pid: 22,
  //   lpSymbol: 'SINGLE-USDC LP',
  //   lpAddresses: {
  //     137: '0x2d485E96e02dcF502B1F8C367523B29d4139d596',
  //     80001: '0x2d485E96e02dcF502B1F8C367523B29d4139d596',
  //   },
  //   token: serializedTokens.single,
  //   quoteToken: serializedTokens.usdc,
  // },
  // {
  //   pid: 23,
  //   lpSymbol: 'BETIFY-MMF LP',
  //   lpAddresses: {
  //     137: '0xe2c5275d86d2fb860f19a2cbbed9967d39aa73e8',
  //     80001: '0xe2c5275d86d2fb860f19a2cbbed9967d39aa73e8',
  //   },
  //   token: serializedTokens.betify,
  //   quoteToken: serializedTokens.mmf,
  // },
  // {
  //   pid: 24,
  //   lpSymbol: 'CGS-WMATIC LP',
  //   lpAddresses: {
  //     137: '0x0DD34d4Ff37D045074b6A077A289eD3163372D47',
  //     80001: '0x0DD34d4Ff37D045074b6A077A289eD3163372D47',
  //   },
  //   token: serializedTokens.cgs,
  //   quoteToken: serializedTokens.wmatic,
  // },
  // {
  //   pid: 25,
  //   lpSymbol: 'AGO-MMF LP',
  //   lpAddresses: {
  //     137: '0x90f27486424e0cc1d98e0144576637673570c903',
  //     80001: '0x90f27486424e0cc1d98e0144576637673570c903',
  //   },
  //   token: serializedTokens.ago,
  //   quoteToken: serializedTokens.mmf,
  // },
  // {
  //   pid: 26,
  //   lpSymbol: 'GRVE-MMF LP',
  //   lpAddresses: {
  //     137: '0x16B7F0Bc8332EDBa5a1B91Ac867c3E5EfD3827e6',
  //     80001: '0x16B7F0Bc8332EDBa5a1B91Ac867c3E5EfD3827e6',
  //   },
  //   token: serializedTokens.grve,
  //   quoteToken: serializedTokens.mmf,
  // },
  // {
  //   pid: 27,
  //   lpSymbol: 'METF-MMF LP',
  //   lpAddresses: {
  //     137: '0xd7385f46FFb877d8c8Fe78E5f5a7c6b2F18C05A7',
  //     80001: '0xd7385f46FFb877d8c8Fe78E5f5a7c6b2F18C05A7',
  //   },
  //   token: serializedTokens.metf,
  //   quoteToken: serializedTokens.mmf,
  // },
  // {
  //   pid: 28,
  //   lpSymbol: 'GOAL-MMF LP',
  //   lpAddresses: {
  //     137: '0xd36c36dE5D1F328BBCb9d74c55EcDa5A2Fb94e23',
  //     80001: '0xd36c36dE5D1F328BBCb9d74c55EcDa5A2Fb94e23',
  //   },
  //   token: serializedTokens.goal,
  //   quoteToken: serializedTokens.mmf,
  // },
  // {
  //   pid: 29,
  //   lpSymbol: 'SRV-MMF LP',
  //   lpAddresses: {
  //     137: '0x2C24fFfD72485d0cB329b95B569e0d7C4061FE82',
  //     80001: '0x2C24fFfD72485d0cB329b95B569e0d7C4061FE82',
  //   },
  //   token: serializedTokens.srv,
  //   quoteToken: serializedTokens.mmf,
  // },
  // {
  //   pid: 30,
  //   lpSymbol: 'MAD-MMF LP',
  //   lpAddresses: {
  //     137: '0x7444491828253452B7a933A5Aa0A74B55A86cdb0',
  //     80001: '0x7444491828253452B7a933A5Aa0A74B55A86cdb0',
  //   },
  //   token: serializedTokens.mad,
  //   quoteToken: serializedTokens.mmf,
  // },
  // {
  //   pid: 8,
  //   lpSymbol: 'ETH-WBTC LP',
  //   lpAddresses: {
  //     137: '0x0101112C7aDdb2E8197922e9cFa17cbAA935ECCc',
  //     80001: '0x0101112C7aDdb2E8197922e9cFa17cbAA935ECCc',
  //   },
  //   token: serializedTokens.weth,
  //   quoteToken: serializedTokens.wbtc,
  // },

  // {
  //   pid: 32,
  //   lpSymbol: 'MUSD-3MM LP',
  //   isStableSwap: true,
  //   numTokens: 2,
  //   basePool: '0xdB04E53eC3FAB887Be2F55C3fD79bC57855bC827',
  //   lpAddresses: {
  //     137: '0xdB04E53eC3FAB887Be2F55C3fD79bC57855bC827',
  //     80001: '0xdB04E53eC3FAB887Be2F55C3fD79bC57855bC827', // LP -> base pool address
  //   },
  //   token: serializedTokens.musdmm3, // 3MMLP token address
  //   quoteToken: serializedTokens.mm3,
  // },
  // {
  //   pid: 33,
  //   lpSymbol: 'MUSD-USDC LP',
  //   lpAddresses: {
  //     137: '0x0B083d50417FEC1390C2C07Eba85f31D5EeFC350',
  //     80001: '0x0B083d50417FEC1390C2C07Eba85f31D5EeFC350', // LP -> base pool address
  //   },
  //   token: serializedTokens.musd,
  //   quoteToken: serializedTokens.usdc,
  // },
  // {
  //   pid: 34,
  //   lpSymbol: 'MMF-MUSD LP',
  //   lpAddresses: {
  //     137: '0xeF2dC4849bDCC120acB7274cd5A557B5145DA149',
  //     80001: '0xeF2dC4849bDCC120acB7274cd5A557B5145DA149', // LP -> base pool address
  //   },
  //   token: serializedTokens.mmf,
  //   quoteToken: serializedTokens.musd,
  // },
  // {
  //   pid: 35,
  //   lpSymbol: 'MATIC-MUSD LP',
  //   lpAddresses: {
  //     137: '0x28F9Eb94c0E954ceE2E0407B084c760BaBf9F39c',
  //     80001: '0x28F9Eb94c0E954ceE2E0407B084c760BaBf9F39c', // LP -> base pool address
  //   },
  //   token: serializedTokens.wmatic,
  //   quoteToken: serializedTokens.musd,
  // },
  // {
  //   pid: 36,
  //   lpSymbol: 'ANN-MMF LP',
  //   lpAddresses: {
  //     137: '0xD41B1d8edEa36795f9BA4802e5A195925E720589',
  //     80001: '0xD41B1d8edEa36795f9BA4802e5A195925E720589', // LP -> base pool address
  //   },
  //   token: serializedTokens.ann,
  //   quoteToken: serializedTokens.mmf,
  // },
  // {
  //   pid: 37,
  //   lpSymbol: 'MAD-SVN LP',
  //   lpAddresses: {
  //     137: '0x4673749a3cb3b3e787b3e88ac36e356dddbe99b9',
  //     80001: '0x4673749a3cb3b3e787b3e88ac36e356dddbe99b9', // LP -> base pool address
  //   },
  //   token: serializedTokens.mad,
  //   quoteToken: serializedTokens.svn,
  // },
  // {
  //   pid: 38,
  //   lpSymbol: 'DONUT-SVN LP',
  //   lpAddresses: {
  //     137: '0xCA5aa86E4e40a353020EcFAc4814A8F61FBB00B0',
  //     80001: '0xCA5aa86E4e40a353020EcFAc4814A8F61FBB00B0', // LP -> base pool address
  //   },
  //   token: serializedTokens.donut,
  //   quoteToken: serializedTokens.svn,
  // },
  // {
  //   pid: 39,
  //   lpSymbol: 'MNG-MMF LP',
  //   lpAddresses: {
  //     137: '0xa7bE5cB8Be6484c06aBb8942fe682797bb9A76Ec',
  //     80001: '0xa7bE5cB8Be6484c06aBb8942fe682797bb9A76Ec', // LP -> base pool address
  //   },
  //   token: serializedTokens.mng,
  //   quoteToken: serializedTokens.mmf,
  // },
  // {
  //   pid: 40,
  //   lpSymbol: 'bMATIC-MATIC LP',
  //   lpAddresses: {
  //     137: '0xB3b66bE228b930C2F319621955CA507aDE3453dd',
  //     80001: '0xB3b66bE228b930C2F319621955CA507aDE3453dd', // LP -> base pool address
  //   },
  //   token: serializedTokens.bcro,
  //   quoteToken: serializedTokens.wmatic,
  // },
  // {
  //   pid: 41,
  //   lpSymbol: 'BTC-USDC LP',
  //   lpAddresses: {
  //     137: '0x120A47f53f28c80e55F4b993189838B86D79EA3a',
  //     80001: '0x120A47f53f28c80e55F4b993189838B86D79EA3a', // LP -> base pool address
  //   },
  //   token: serializedTokens.wbtc,
  //   quoteToken: serializedTokens.usdc,
  // },
  // {
  //   pid: 42,
  //   lpSymbol: 'bcroMM LP',
  //   isStableSwap: true,
  //   basePool: '0xbF369D9c0Ab3107F4823a39B2fD2Ca0Ff5310425',
  //   lpAddresses: {
  //     137: '0xB996cE5bd3551C3A95A39AFb7dfdDD552657e38e',
  //     80001: '0xB996cE5bd3551C3A95A39AFb7dfdDD552657e38e', // LP -> base pool address
  //   },
  //   numTokens: 2,
  //   token: serializedTokens.mmbcro, // 3MMLP token address
  //   quoteToken: serializedTokens.wmatic,
  // },
]

const testnetFarms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 0,
    nftBoost: true,
    lpSymbol: 'MMF',
    lpAddresses: {
      80001: '0x904Bd5a5AAC0B9d88A0D47864724218986Ad4a3a',
      137: '0x904Bd5a5AAC0B9d88A0D47864724218986Ad4a3a',
    },
    token: serializedTokens.workbench,
    quoteToken: serializedTokens.wmatic,
  },
  // {
  //   pid: 1,
  //   lpSymbol: 'MATIC-ETH LP',
  //   lpAddresses: {
  //     80001: '0xD37d267B2161035A9CF2023F9753CddbfC8B90F3',
  //     137: '',
  //   },
  //   token: serializedTokens.eth,
  //   quoteToken: serializedTokens.wmatic,
  // },
  // {
  //   pid: 2,
  //   lpSymbol: 'MATIC-WBTC LP',
  //   lpAddresses: {
  //     80001: '0xbDAA80e9EA59bFD3A8AE5705c66804F2e1D9D508',
  //     137: '',
  //   },
  //   token: serializedTokens.wbtc,
  //   quoteToken: serializedTokens.wmatic,
  // },
  // {
  //   pid: 3,
  //   lpSymbol: 'MATIC-MMF LP',
  //   lpAddresses: {
  //     80001: '0x3F3465b412B6017E20AF7ABFFAB67698de8cFa7a',
  //     137: '',
  //   },
  //   token: serializedTokens.mmf,
  //   quoteToken: serializedTokens.wmatic,
  // },
  // {
  //   pid: 4,
  //   lpSymbol: 'MATIC-USDC LP',
  //   lpAddresses: {
  //     80001: '0x5323D7CE6a12282c0448d255d8B5EDc9918E52Da',
  //     137: '',
  //   },
  //   token: serializedTokens.usdc,
  //   quoteToken: serializedTokens.wmatic,
  // },
  // {
  //   pid: 5,
  //   lpSymbol: 'MMF-USDC LP',
  //   lpAddresses: {
  //     80001: '0x9e3A0434ED428dFDe5ace779ead35e141DD95eFf',
  //     137: '',
  //   },
  //   token: serializedTokens.mmf,
  //   quoteToken: serializedTokens.usdc,
  // },
]

const farms: SerializedFarmConfig[] = mainnetFarms

export default farms

import { useCallback } from 'react'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { NoBscProviderError } from '@binance-chain/bsc-connector'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector'
import { ConnectorNames, connectorLocalStorageKey } from '@polymmfinance/uikit'
import { connectorsByName } from 'utils/web3React'
import { setupNetwork } from 'utils/wallet'
import useToast from 'hooks/useToast'
import { profileClear } from 'state/profile'
import { useAppDispatch } from 'state'
import { useTranslation } from 'contexts/Localization'
import { clearAllTransactions } from 'state/transactions/actions'
import { resetUserNftState } from '../state/nftMarket/reducer'

const useAuth = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { account, activate, deactivate } = useWeb3React()
  const { toastError } = useToast()

  const login = useCallback(
    (connectorID: ConnectorNames) => {
      const connector = connectorsByName[connectorID]
      if (connector) {
        // window.gtag('event', 'wallet', {
        //   hitType: 'event',
        //   eventCategory: 'Wallet',
        //   eventAction: 'connect',
        //   eventLabel: connectorID,
        // })

        activate(connector, async (error: Error) => {
          if (error instanceof UnsupportedChainIdError && connectorID !== ConnectorNames.CDCDefiWallet) {
            const hasSetup = await setupNetwork()
            if (hasSetup) {
              activate(connector)
            }
          } else {
            window.localStorage.removeItem(connectorLocalStorageKey)
            if (error instanceof NoEthereumProviderError || error instanceof NoBscProviderError) {
              toastError(t('Provider Error'), t('No provider was found'))
            } else if (
              error instanceof UserRejectedRequestErrorInjected ||
              error instanceof UserRejectedRequestErrorWalletConnect
            ) {
              if (connector instanceof WalletConnectConnector) {
                const walletConnector = connector as WalletConnectConnector
                walletConnector.walletConnectProvider = null
              }
              toastError(t('Authorization Error'), t('Please authorize to access your account'))
            } else {
              /** :TODO: remote this code */
              if (error instanceof UnsupportedChainIdError && connectorID === ConnectorNames.CDCDefiWallet) {
                toastError('Wrong Network', 'Please switch to [Polygon Beta] on Chrome Wallet Extension to connect')
                return
              }
              toastError(error.name, error.message)
            }
          }
        })
      } else {
        toastError(t('Unable to find connector'), t('The connector config is wrong'))
      }
    },
    [t, activate, toastError],
  )

  const logout = useCallback(() => {
    dispatch(profileClear())
    deactivate()
    // This localStorage key is set by @web3-react/walletconnect-connector
    if (window.localStorage.getItem('walletconnect')) {
      connectorsByName.walletconnect.close()
      connectorsByName.walletconnect.walletConnectProvider = null
    }
    window.localStorage.removeItem(connectorLocalStorageKey)
  }, [deactivate, dispatch])

  const getUserInfo = useCallback(async (connectorID: ConnectorNames): Promise<{
    username: string
    walletAddress: string
  }> => {
    const connector = connectorsByName[connectorID]
    if (connectorID === ConnectorNames.UnstoppableDomains && connector?.uauth?.user) {
      return new Promise((resolve, reject) =>
        connector.uauth
          .user()
          .then((data) => {
            if (account.toLowerCase() === data.wallet_address.toLowerCase()) {
              resolve({
                username: data.sub,
                walletAddress: data.wallet_address
              })
            } else {
              resolve({
                username: null,
                walletAddress: account
              })
            }
          })
          .catch((e) => reject(e)),
      )
    }
    return null
  }, [account])

  return { login, logout, getUserInfo }
}

export default useAuth

import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  connectorLocalStorageKey,
  ConnectorNames,
  CopyIcon,
  Flex, IconButton,
  InjectedModalProps,
  LinkExternal,
  Message,
  Skeleton,
  Text
} from '@polymmfinance/uikit'
import { useWeb3React } from '@web3-react/core'
import useTokenBalance, { FetchStatus, useGetBnbBalance } from 'hooks/useTokenBalance'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'
import { getBscScanLink } from 'utils'
import { getFullDisplayBalance, formatBigNumber } from 'utils/formatBalance'
import tokens from 'config/constants/tokens'
import CopyAddress from './CopyAddress'

interface WalletInfoProps {
  hasLowBnbBalance: boolean
  onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({ hasLowBnbBalance, onDismiss }) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { balance, fetchStatus } = useGetBnbBalance()
  const { balance: crocBalance, fetchStatus: crocFetchStatus } = useTokenBalance(tokens.mmf.address)
  const { logout, getUserInfo } = useAuth()
  const [userData, setUserData] = useState({} as {
    username: string
    walletAddress: string
  })

  const handleLogout = () => {
    onDismiss()
    logout()
  }

  useEffect(() => {
    const connectorId = window.localStorage.getItem(connectorLocalStorageKey) as ConnectorNames
    async function fetchUserData() {
      const data = await getUserInfo(connectorId)
      setUserData(data)
    }
    if (connectorId === ConnectorNames.UnstoppableDomains) {
      fetchUserData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Text color="secondary" fontSize="12px" textTransform="uppercase" fontWeight="bold" mb="8px">
        {t('Your Address')}
      </Text>
      <CopyAddress account={account} mb="24px" />
      {
        userData.username && (
          <>
            <Text color="secondary" fontSize="12px" textTransform="uppercase" fontWeight="bold" mb="8px">
              {t('Your username')}
            </Text>
            <CopyAddress
              account={userData.username}
              mb="24px"
              StartIcon={() => (
                <IconButton variant="text" title="" onClick={() => window.open(`https://ud.me/${userData.username}`, '_blank')}>
                  <img alt="ud" src="./images/partners/unstoppable-domains.svg" width="24px" />
                </IconButton>
              )}
            />
          </>
        )
      }
      {hasLowBnbBalance && (
        <Message variant="warning" mb="24px">
          <Box>
            <Text fontWeight="bold">{t('MATIC Balance Low')}</Text>
            <Text as="p">{t('You need MATIC for transaction fees.')}</Text>
          </Box>
        </Message>
      )}
      <Flex alignItems="center" justifyContent="space-between">
        <Text color="textSubtle">{t('MATIC Balance')}</Text>
        {fetchStatus !== FetchStatus.SUCCESS ? (
          <Skeleton height="22px" width="60px" />
        ) : (
          <Text>{formatBigNumber(balance, 6)}</Text>
        )}
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" mb="24px">
        <Text color="textSubtle">{t('MMF Balance')}</Text>
        {crocFetchStatus !== FetchStatus.SUCCESS ? (
          <Skeleton height="22px" width="60px" />
        ) : (
          <Text>{getFullDisplayBalance(crocBalance, 18, 3)}</Text>
        )}
      </Flex>
      <Flex alignItems="left" justifyContent="end" mb="24px">
        <LinkExternal href={getBscScanLink(account, 'address')}>{t('View on polygon explorer')}</LinkExternal>
      </Flex>
      <Button variant="secondary" width="100%" onClick={handleLogout}>
        {t('Disconnect Wallet')}
      </Button>
    </>
  )
}

export default WalletInfo

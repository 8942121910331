import { Token, ChainId } from '@polymmfinance/sdk'
import tokens from './tokens'
import { Ifo } from './types'

// const mmfCroLPToken = new Token(ChainId.MAINNET, farms[1].lpAddresses[ChainId.MAINNET], 18, farms[1].lpSymbol)
// export const hknSvnLPToken = new Token(ChainId.MAINNET, '0x0fcffa1eD6b91B50dc80bB652f1111464A46338F', 18, 'HKN/SVN LP')
export const mmfMmoLPToken = new Token(ChainId.MAINNET, '0xd6a88114DCC01f72D1B311aD6adF860724ff35d3', 18, 'MMF/MMO LP')
export const dsfMmfLPToken = new Token(ChainId.MAINNET, '0xfc48B66b9119f1d5fD7C8e72E7e489a5D6C0EF55', 18, 'DSF/MMF LP')

const ifos: Ifo[] = [
  {
    id: 'MMF',
    address: '0x67a41ee6c5D807788477F7F66fe50876eD5655D1',
    isActive: false,
    name: 'MM Finance (MMF)',
    poolBasic: {
      saleAmount: '5,000,000 MMF',
      raiseAmount: '$1,000,000 USD',
      cakeToBurn: '50% of MMF raised',
      distributionRatio: 0.5,
    },
    poolUnlimited: {
      saleAmount: '5,000,000 MMF',
      raiseAmount: '$1,000,000 USD',
      cakeToBurn: '50% of MMF raised',
      distributionRatio: 0.5,
    },
    description:
      'The beginning of a new era. MM.Finance is an entire ecosystem of DeFi apps and tools that is planned synergistically to build huge value. We are a community focused DEX with one of the lowest trading fees - 0.17% on the entire Polygon Network.',
    currency: tokens.usdc,
    token: tokens.mmf,
    startTimestamp: 1660309200,
    endTimestamp: 1660395600,
    campaignId: '1',
    articleUrl: 'https://medium.com/@MMFinance/74d80a5d469f',
    tokenOfferingPrice: '$0.2',
    projectSite: 'https://polymm.finance/',
    version: 1,
    loadIfoData: true,
  },
  {
    id: 'mmo',
    address: '0x01AB57A080C4434156b52993170A19ce02C48B84',
    isActive: false,
    name: 'MM Optimizer (MMO)',
    poolBasic: {
      saleAmount: '300,000 MMO',
      raiseAmount: '3,000,000 MMF',
      cakeToBurn: '1,500,000 MMF',
      distributionRatio: 0.5,
    },
    poolUnlimited: {
      saleAmount: '300,000 MMO',
      raiseAmount: '3,000,000 MMF',
      cakeToBurn: '1,500,000 MMF',
      distributionRatio: 0.5,
    },
    description: `MM Optimizer - The top autocompounding yield platform on Polygon. Maximize your yields.\nOur maximizer vaults help you compound your yields up to 48 times a day to ensure you are optimizing your yields.`,
    currency: tokens.mmf,
    token: tokens.mmo,
    startTimestamp: 1661000400,
    endTimestamp: 1661086800,
    campaignId: '2',
    articleUrl: 'https://mmfinance.gitbook.io/optimizer',
    tokenOfferingPrice: '10 MMF',
    projectSite: 'https://medium.com/@MMFinance/2nd-launchpad-mm-optimizer-123c3f5f00e',
    version: 1,
    loadIfoData: true,
  },
  {
    id: 'autos',
    address: '0xeb6239D015d7D438DfB4180B53A639bc9f3f1227',
    isActive: false,
    name: 'AUTO SINGLE (AUTOS)',
    poolBasic: {
      saleAmount: '8,000,000 AUTOS',
      raiseAmount: '$500,000 in MMF/MMO LP',
      cakeToBurn: '100% of MMO raised',
      distributionRatio: 0.5,
    },
    poolUnlimited: {
      saleAmount: '8,000,000 AUTOS',
      raiseAmount: '$500,000 in MMF/MMO LP',
      cakeToBurn: '100% of MMO raised',
      distributionRatio: 0.5,
    },
    description: `AutoSingle is the new cutting-edge product built by Single Finance Lab. Aimed at providing automated yield farming strategies with well back-tested algo bots, it is built on top of Single Finance, a leveraged yield farming platform.`,
    currency: mmfMmoLPToken,
    token: tokens.autos,
    startTimestamp: 1661673600,
    endTimestamp: 1661688000,
    campaignId: '3',
    articleUrl: 'https://medium.com/@MMFinance/4th-launchpad-autosingle-25a1bc319fcb',
    tokenOfferingPrice: '$0.0625',
    projectSite: 'https://autosingle.singlefinance.io/',
    version: 1,
    loadIfoData: true,
    isPreparing: false,
  },
  {
    id: 'dsf',
    address: '0x9F799772430468bFe828a669269f8b3e856bFE30',
    isActive: false,
    name: 'DawnStar Finance (DSF)',
    poolBasic: {
      saleAmount: '2,000,000 DSF',
      raiseAmount: '2,000,000 of MMF via MMF/MMO LP',
      cakeToBurn: '100% of MMO raised',
      distributionRatio: 0.5,
    },
    poolUnlimited: {
      saleAmount: '2,000,000 DSF',
      raiseAmount: '2,000,000 of MMF via MMF/MMO LP',
      cakeToBurn: '100% of MMO raised',
      distributionRatio: 0.5,
    },
    description: `The First Algorithmic Stablecoin on Polygon backed by real yields & pegged to the price of 1 MMF via seigniorage.`,
    currency: mmfMmoLPToken,
    token: tokens.dsf,
    startTimestamp: 1661832000,
    endTimestamp: 1661846400,
    campaignId: '4',
    articleUrl: 'https://medium.com/@MMFinance/launch-of-dawnstar-finance-2b51a6c96ce',
    tokenOfferingPrice: '1 MMF',
    projectSite: 'https://mmfinance.gitbook.io/dawnstar-finance',
    version: 1,
    loadIfoData: true,
    isPreparing: false,
  },
  {
    id: 'croissant',
    address: '0x6b5c25FFa9EDde9ea4d1C649247D041486a715Fb',
    isActive: false,
    name: 'Croissant Games (CROISSANT)',
    poolBasic: {
      saleAmount: '25,000,000 MUFFIN',
      raiseAmount: '$500,000 in DSF/MMF LP',
      cakeToBurn: '50% of MMF raised',
      distributionRatio: 0.5,
    },
    poolUnlimited: {
      saleAmount: '25,000,000 MUFFIN',
      raiseAmount: '$500,000 in DSF/MMF LP',
      cakeToBurn: '50% of MMF raised',
      distributionRatio: 0.5,
    },
    description:
      'The #1 decentralized gamefi platform on Cronos coming to Polygon with chainlink integration for fair gaming',
    currency: dsfMmfLPToken,
    token: tokens.muffin,
    startTimestamp: 1662278400,
    endTimestamp: 1662292800,
    campaignId: '5',
    articleUrl: 'https://medium.com/@MMFinance/6th-launchpad-croissant-games-b88ee28433d6',
    tokenOfferingPrice: '$0.02',
    projectSite: 'https://croissant.games/',
    version: 1,
    loadIfoData: true,
    isPreparing: false,
  },
  {
    id: 'love',
    address: '0x01A104728739A111e66b9d1e661Ef2718BcDF702',
    isActive: false,
    name: 'FrontlineDAO (LOVE)',
    poolBasic: {
      saleAmount: '1,000,000 LOVE',
      raiseAmount: '$200,000 in MMF/DSF LP',
      cakeToBurn: '100% of MMO raised',
      distributionRatio: 0.5,
    },
    poolUnlimited: {
      saleAmount: '1,000,000 LOVE',
      raiseAmount: '$200,000 in MMF/DSF LP',
      cakeToBurn: '100% of DSF raised',
      distributionRatio: 0.5,
    },
    description: `First Fractional-Algorithmic Synthetic Token pegged to the value of 1 MMF on Polygon Chain & a Premier Launchpad Platform.`,
    currency: dsfMmfLPToken,
    token: tokens.love,
    startTimestamp: 1665460800,
    endTimestamp: 1665489600,
    campaignId: '6',
    articleUrl: 'https://medium.com/@MMFinance/d6b5494d23e0',
    tokenOfferingPrice: '$0.2',
    projectSite: 'https://frontlinedao.finance',
    version: 1,
    loadIfoData: true,
    isPreparing: false,
  },
  {
    id: 'famarmy',
    address: '0xCdFf7E91F64c5E0772C8170b1e43B86b7ae4832a',
    isActive: true,
    isWhitelist: false,
    name: 'FAM.ARMY (FAM)',
    poolBasic: {
      saleAmount: '300,000 FAM',
      raiseAmount: '$300,000 in USDC.e',
      cakeToBurn: 'MMF Buyback : 10% of total raised',
      distributionRatio: 0.5,
    },
    poolUnlimited: {
      saleAmount: '300,000 FAM',
      raiseAmount: '$300,000 in USDC.e',
      cakeToBurn: 'MMF Buyback : 10% of total raised',
      distributionRatio: 0.5,
    },
    description: `Fam.Army is the first of its kind, that combines the strengths of Defi X Social Fi. Its goal is to create an unique experience around Social Fi and value/portfolio growth.`,
    currency: tokens.usdc,
    token: tokens.fam,
    startTimestamp: 1698642000,
    endTimestamp: 1698814800,
    campaignId: '7',
    articleUrl: 'https://medium.com/@MMFinance/8th-launchpad-fam-army-5cc7519fce85',
    tokenOfferingPrice: '$0.5',
    projectSite: 'https://fam.army',
    version: 1,
    loadIfoData: true,
    isPreparing: false,
  },
  {
    id: 'famarmywhitelist',
    address: '0xD56345F85C8e9862613fD64d28250FB9a817DE4c',
    isActive: true,
    isWhitelist: true,
    name: 'FAM.ARMY (FAM)',
    poolBasic: {
      saleAmount: '300,000 FAM',
      raiseAmount: '$300,000 in USDC.e',
      cakeToBurn: 'MMF Buyback : 10% of total raised',
      distributionRatio: 0.5,
    },
    poolUnlimited: {
      saleAmount: '300,000 FAM',
      raiseAmount: '$300,000 in USDC.e',
      cakeToBurn: 'MMF Buyback : 10% of total raised',
      distributionRatio: 0.5,
    },
    description: `Fam.Army is the first of its kind, that combines the strengths of Defi X Social Fi. Its goal is to create an unique experience around Social Fi and value/portfolio growth.`,
    currency: tokens.usdc,
    token: tokens.fam,
    startTimestamp: 1698642000,
    endTimestamp: 1698814800,
    campaignId: '8',
    articleUrl: 'https://medium.com/@MMFinance/8th-launchpad-fam-army-5cc7519fce85',
    tokenOfferingPrice: '$0.5',
    projectSite: 'https://fam.army',
    version: 1,
    loadIfoData: true,
    isPreparing: false,
  },
  // {
  //   id: 'donut',
  //   address: '0xe3c5fca9D21F67636287bF344e29b28980Ff9C3E',
  //   isActive: false,
  //   name: 'Donut Finance (DONUT)',
  //   poolBasic: {
  //     saleAmount: '1,500,000 DONUT',
  //     raiseAmount: '$1,500,000 in HKN/SVN LP',
  //     cakeToBurn: '50% of HKN/SVN LP raised',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '1,500,000 DONUT',
  //     raiseAmount: '$1,500,000 in HKN/SVN LP',
  //     cakeToBurn: '50% of HKN/SVN LP raised',
  //     distributionRatio: 0.5,
  //   },
  //   description:
  //     'Donut Finance seeks to be the first of its kind platform that functions as a yield aggregator and Treasury Inflation-Protected (TIP) Bonds Issuer inspired by the mechanics behind TIPS in the real world.',
  //   currency: hknSvnLPToken,
  //   token: tokens.donut,
  //   startTimestamp: 1653537600,
  //   endTimestamp: 1653559200,
  //   campaignId: '15',
  //   articleUrl: 'https://medium.com/@MMFinance/14th-launchpad-donut-finance-dd04ec3f9e02',
  //   tokenOfferingPrice: '$1',
  //   projectSite: 'https://donut.financial/',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // {
  //   id: 'hkn',
  //   address: '0x344b7c1de8d55455D51FE8167C37b9a086D87279',
  //   isActive: false,
  //   name: 'Hakuna Matata (HKN)',
  //   poolBasic: {
  //     saleAmount: '7,000,000 HKN',
  //     raiseAmount: '7,000,000 in SVN',
  //     cakeToBurn: '35% of SVN raised',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '7,000,000 HKN',
  //     raiseAmount: '7,000,000 in SVN',
  //     cakeToBurn: '35% of SVN raised',
  //     distributionRatio: 0.5,
  //   },
  //   description:
  //     'A project voted by the community, for the community, by MM team. Hakuna Matata is an algorithmic stable coin platform with the main ecosystem token $HKN pegged to the value of 1 SVN. $HKN is an SVN algorithmic pegged token with features that seek to enhance the MM ecosystem',
  //   currency: tokens.svn,
  //   token: tokens.hkn,
  //   startTimestamp: 1651906800,
  //   endTimestamp: 1651993200,
  //   campaignId: '14',
  //   articleUrl: 'https://medium.com/@MMFinance/13th-launchpad-hakuna-91e44f611cd4',
  //   tokenOfferingPrice: '1 SVN',
  //   projectSite: 'https://hkn.finance/',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // {
  //   id: 'burrow',
  //   address: '0xA88e37EDFF70158728EBbe56C4F162eDE0CF1B28',
  //   isActive: false,
  //   name: 'MMF Money (BURROW)',
  //   poolBasic: {
  //     saleAmount: '15,000,000 BURROW',
  //     raiseAmount: '$1,500,000 in SVN',
  //     cakeToBurn: '67% of SVN raised',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '15,000,000 BURROW',
  //     raiseAmount: '$1,500,000 in SVN',
  //     cakeToBurn: '67% of SVN raised',
  //     distributionRatio: 0.5,
  //   },
  //   description:
  //     'The $BURROW token will be a governance token for the MMF Money platform, in a limited supply of 300 million. This $BURROW token will be distributed in launchpad, as well as to be distributed to believers of the MMF Money platform as yield farming incentives in the beginning',
  //   currency: tokens.svn,
  //   token: tokens.burrow,
  //   startTimestamp: 1651125600,
  //   endTimestamp: 1651212000,
  //   campaignId: '13',
  //   articleUrl: 'https://medium.com/@MMFinance/12th-launchpad-burrow-ad1c9ee32cd2',
  //   tokenOfferingPrice: '$0.10',
  //   projectSite: 'https://mmf.money/',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // {
  //   id: 'lion',
  //   address: '0xE16a77554A217427e8DE3EB8b2013E30514D8b63',
  //   isActive: false,
  //   name: 'Scrub Finance (LION)',
  //   poolBasic: {
  //     saleAmount: '300,000 LION',
  //     raiseAmount: '300,000 SVN',
  //     cakeToBurn: '30% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '300,000 LION',
  //     raiseAmount: '300,000 SVN',
  //     cakeToBurn: '30% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   description:
  //     'Scrub Finance is a multi-token protocol which consists of the following two tokens: Lion ($LION) and Tiger Shares ($TIGER). LION is Pegged to the price of 1 SVN via seigniorage. LION is also the first algorithmic stablecoin pegged to SVN.',
  //   currency: tokens.svn,
  //   token: tokens.lion,
  //   startTimestamp: 1649397600,
  //   endTimestamp: 1649419200,
  //   campaignId: '12',
  //   articleUrl: 'https://medium.com/@MMFinance/11th-launchpad-scrub-finance-ea8ff0989e7',
  //   tokenOfferingPrice: '1 SVN',
  //   projectSite: 'https://scrub.finance/',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // {
  //   id: 'pes',
  //   address: '0x6bAf65152D61184D74f9B3ce7c1584fC62bad085',
  //   isActive: false,
  //   name: 'Pegasus Dollar (PES)',
  //   poolBasic: {
  //     saleAmount: '5770 PES',
  //     raiseAmount: '$750,000 in SVN',
  //     cakeToBurn: '30% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '5770 PES',
  //     raiseAmount: '$750,000 in SVN',
  //     cakeToBurn: '30% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   description:
  //     'The Pegasus Dollar Protocol was created by the Pegasus team as a Polygon Chain Algorithmic Token pegged to METF. It involves an innovative solution that can adjust the stable coin’s supply deterministically to move the price of the stable coin in the direction of a target price to bring programmability and interoperability to DeFi.',
  //   currency: tokens.svn,
  //   token: tokens.pes,
  //   startTimestamp: 1648879200,
  //   endTimestamp: 1648900800,
  //   campaignId: '11',
  //   articleUrl: 'https://medium.com/@MMFinance/10th-launchpad-pegasus-dollar-471f1d119e2',
  //   tokenOfferingPrice: 'Peg to price of METF',
  //   projectSite: 'https://pegasusdollar.finance/',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // {
  //   id: 'goal',
  //   address: '0x856B82eb691564F8b095586254229236242E84Dc',
  //   isActive: false,
  //   name: 'Polygon FC (GOAL)',
  //   poolBasic: {
  //     saleAmount: '20,000,000 GOAL',
  //     raiseAmount: '$600,000 MMF',
  //     cakeToBurn: '30% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '20,000,000 GOAL',
  //     raiseAmount: '$600,000 MMF',
  //     cakeToBurn: '30% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   description:
  //     'Polygon FC is the first ever R2E (Risk-To-Earn) platform on the entire Polygon Chain. It combines both non-fungible tokens (NFTs) and elements of Decentralised Finance (DeFi) to bring yield farming to players. Project took inspiration from renowned Wolf Game on Ethereum and Police & Thief on Avax.',
  //   currency: tokens.mmf,
  //   token: tokens.goal,
  //   startTimestamp: 1647763200,
  //   endTimestamp: 1647784800,
  //   campaignId: '10',
  //   articleUrl: 'https://medium.com/@MMFinance/9th-launchpad-polygon-fc-a32d5880cbce',
  //   tokenOfferingPrice: '$0.03',
  //   projectSite: 'https://polygonfc.club/',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // {
  //   id: 'metf',
  //   address: '0x6727E4714b2600c3e537819F70c0ef3b8b26e114',
  //   isActive: false,
  //   name: 'MM ETF (METF)',
  //   poolBasic: {
  //     saleAmount: '50,000 METF',
  //     raiseAmount: '750,000 MMF',
  //     cakeToBurn: '0% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '50,000 METF',
  //     raiseAmount: '750,000 MMF',
  //     cakeToBurn: '0% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   description: `MM ETF is the first decentralized ETF protocol that runs on METF token. The DTF term is thus coined by our team as the DEX Traded Fund, being the first of its kind. Each METF token is backed by a basket of assets.`,
  //   currency: tokens.mmf,
  //   token: tokens.metf,
  //   startTimestamp: 1646798400,
  //   endTimestamp: 1646884800,
  //   campaignId: '9',
  //   articleUrl: 'https://mmfinance.gitbook.io/mm-etf/',
  //   tokenOfferingPrice: '15 MMF',
  //   projectSite: 'https://mmfinance.gitbook.io/mm-etf/',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // {
  //   id: 'agora',
  //   address: '0x0971044Ad3AbEdFa8c9f31F55419664d682697Cc',
  //   isActive: false,
  //   name: 'Agora NFT Marketplace (AGORA)',
  //   poolBasic: {
  //     saleAmount: '6,000,000 AGORA',
  //     raiseAmount: '$600,000',
  //     cakeToBurn: '40% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '6,000,000 AGORA',
  //     raiseAmount: '$600,000',
  //     cakeToBurn: '40% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   description: `Agora NFT marketplace is the first of its kind to distribute 100% of its profit back to their token holders. $AGO is also used for voting in the DAGO. All the treasury and the development will be decided through the DAGO, the decentralized governance system for Agora.`,
  //   currency: tokens.mmf,
  //   token: tokens.ago,
  //   startTimestamp: 1645430400,
  //   endTimestamp: 1645452000,
  //   campaignId: '8',
  //   articleUrl: 'https://medium.com/@MMFinance/7th-launchpad-agora-8c5a1f47489e',
  //   tokenOfferingPrice: '$0.1',
  //   projectSite: 'https://agoracro.com/',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // {
  //   id: 'betify',
  //   address: '0x2fB07e9106Bd777a5157bC66fBEae085BC2D8508',
  //   isActive: false,
  //   name: 'Gamblefi (BETIFY)',
  //   poolBasic: {
  //     saleAmount: '100,000 BETIFY',
  //     raiseAmount: '$1,000,000',
  //     cakeToBurn: '30% of BETIFY raised',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '100,000 BETIFY',
  //     raiseAmount: '$1,000,000',
  //     cakeToBurn: '30% of BETIFY raised',
  //     distributionRatio: 0.5,
  //   },
  //   description: `GambleFi is the first Rebase DAO featuring a Zero Loss Betting system, where all users have no risk of losing their capital while helping with the exponential growth of the treasury.`,
  //   currency: tokens.mmf,
  //   token: tokens.betify,
  //   startTimestamp: 1644998400,
  //   endTimestamp: 1645020600,
  //   campaignId: '7',
  //   articleUrl: 'https://gamblefi.medium.com/gamblefi-x-mm-finance-launch-a010e6b22c15',
  //   tokenOfferingPrice: '$10',
  //   projectSite: 'https://gamblefi.io',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // {
  //   id: 'svn',
  //   address: '0x4eB5C91A7aC3681DCEC901d8dE8C19E5240A75AE',
  //   isActive: false,
  //   name: 'Savanna Finance (SVN)',
  //   poolBasic: {
  //     saleAmount: '1,500,000 SVN',
  //     raiseAmount: '1,500,000 MMF',
  //     cakeToBurn: '40% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '1,500,000 SVN',
  //     raiseAmount: '1,500,000 MMF',
  //     cakeToBurn: '40% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   description: `An algorithmic stable coin platform with our token $SVN pegged to 1 MMF. Aimed at creating a high-yield, safe farming environment whilst ensuring high liquidity for MMF/SVN at the same time.`,
  //   currency: tokens.mmf,
  //   token: tokens.svn,
  //   startTimestamp: 1644552000,
  //   endTimestamp: 1644638400,
  //   campaignId: '6',
  //   articleUrl: 'https://medium.com/@MMFinance/5th-launchpad-svn-savanna-finance-358d23cf7ba5',
  //   tokenOfferingPrice: '1 MMF',
  //   projectSite: 'https://svn.finance',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // {
  //   id: 'crp',
  //   address: '0x8fD0973Ef39165656D1e5b24C9323d3b4629823b',
  //   isActive: false,
  //   name: 'MATIC Predict (CRP)',
  //   poolBasic: {
  //     saleAmount: '200,000 CRP',
  //     raiseAmount: '$500,000',
  //     cakeToBurn: '50% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '200,000 CRP',
  //     raiseAmount: '$500,000',
  //     cakeToBurn: '50% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   description: `MATIC Predict is a prediction market maker GameFi on the Polygon Chain, it’s a play-to-earn (predict-to-earn) GameFi. `,
  //   currency: tokens.mmf,
  //   token: tokens.crp,
  //   startTimestamp: 1643014800,
  //   endTimestamp: 1643036400,
  //   campaignId: '5',
  //   articleUrl: 'https://medium.com/@MMFinance/mmfinance-announcing-4th-launchpad-b86eff5f98f6',
  //   tokenOfferingPrice: '$2.5',
  //   projectSite: 'https://cropredict.finance/#',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // {
  //   id: 'croissant',
  //   address: '0x363D1C27d163c91cF3d652d370d4b5C56A7D948E',
  //   isActive: false,
  //   name: 'Croissant games (MATICISSANT)',
  //   poolBasic: {
  //     saleAmount: '50,000,000 MATICISSANT',
  //     raiseAmount: '$1,000,000',
  //     cakeToBurn: '40% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '50,000,000 MATICISSANT',
  //     raiseAmount: '$1,000,000',
  //     cakeToBurn: '40% of MMF raised',
  //     distributionRatio: 0.5,
  //   },
  //   description: `Unlike traditional games that operate in black boxes, Croissant Games runs on smart contracts that are fair, transparent, non-custodian, and immutable.`,
  //   currency: tokens.mmf,
  //   token: tokens.croissant,
  //   startTimestamp: 1642064400,
  //   endTimestamp: 1642078800,
  //   campaignId: '4',
  //   articleUrl: 'https://medium.com/@MMFinance/514406fab572',
  //   tokenOfferingPrice: '$0.02',
  //   projectSite: 'https://croissant.games/',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // {
  //   id: 'mimas',
  //   address: '0x466B8E113a7c3da3871Bbca2698BE1BE2FC6cf0d',
  //   isActive: false,
  //   name: 'MIMAS FINANCE (MIMAS)',
  //   poolBasic: {
  //     saleAmount: '10,000,000 MIMAS',
  //     raiseAmount: '$2,000,000',
  //     cakeToBurn: '$1,000,000',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '10,000,000 MIMAS',
  //     raiseAmount: '$2,000,000',
  //     cakeToBurn: '$1,000,000',
  //     distributionRatio: 0.5,
  //   },
  //   description: `Mimas Finance is an algorithmic money market and liquid staking protocol on the Polygon blockchain. Built by the same team behind Tranquil on the Harmony blockchain, it allows users to supply and borrow assets, while receiving or paying interest in a completely permission-less and decentralized way. Borrowing and lending interest is set based on market demand, and loans are over-collateralized to ensure solvency.`,
  //   currency: mmfCroLPToken,
  //   token: tokens.mimas,
  //   startTimestamp: 1641204000,
  //   endTimestamp: 1641290400,
  //   campaignId: '3',
  //   articleUrl: 'https://medium.com/@MMFinance/mm-finance-2nd-launchpad-mimas-finance-d47fa8901f8a',
  //   tokenOfferingPrice: '$0.2',
  //   projectSite: 'https://mimas.finance/',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // {
  //   id: 'mmo',
  //   address: '0x22E5425015E6446c81215Bd32FDdF9B842a6721F',
  //   isActive: false,
  //   name: 'MM Optimizer (MMO)',
  //   poolBasic: {
  //     saleAmount: '625,000 MMO',
  //     raiseAmount: '$2,500,000',
  //     cakeToBurn: '$1,250,000',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '625,000 MMO',
  //     raiseAmount: '$2,500,000',
  //     cakeToBurn: '$1,250,000',
  //     distributionRatio: 0.5,
  //   },
  //   description: `MM Optimizer is the go-to state-of-the-art yield aggregator & optimizer on the Polygon Chain. Within the DeFi ecosystem, the best opportunities to compound your assets can be found within the MMO platform offering a suite of yield-generating products, focused on accessibility, simplicity, optimization and longevity.`,
  //   currency: mmfCroLPToken,
  //   token: tokens.mmo,
  //   startTimestamp: 1640404800,
  //   endTimestamp: 1640491200,
  //   campaignId: '511140000',
  //   articleUrl: 'https://mmfinance.gitbook.io/optimizer',
  //   tokenOfferingPrice: '$4',
  //   projectSite: 'https://vaults.polymm.finance/',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // {
  //   id: 'mmf',
  //   address: '0x187F71C2A9B64bC0011D6cBf2bcA4aAd56631024',
  //   isActive: true,
  //   name: 'MM Finance token (MMF)',
  //   poolBasic: {
  //     saleAmount: '4,500,000 MMF',
  //     raiseAmount: '$900,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '4,500,000 MMF',
  //     raiseAmount: '$900,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.5,
  //   },
  //   description: `The first token to be launched is an expansion of the MATICNOS ecosystem. $MMF will play a part in cementing our standing amongst the leading DEXes on the MATICNOS chain`,
  //   currency: tokens.wmatic,
  //   token: tokens.mmf,
  //   startTimestamp: 1638703160,
  //   endTimestamp: 1638875960,
  //   campaignId: '511140000',
  //   articleUrl: 'https://mmfinance.gitbook.io/docs/',
  //   tokenOfferingPrice: 0.2,
  //   projectSite: 'https://polymm.finance/',
  //   version: 1,
  //   loadIfoData: true,
  // },
  // {
  //   id: 'dar',
  //   address: '0xb6eF1f36220397c434A6B15dc5EA616CFFDF58CE',
  //   isActive: false,
  //   name: 'Mines of Dalarnia (DAR)',
  //   poolBasic: {
  //     saleAmount: '6,000,000 DAR',
  //     raiseAmount: '$450,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.5,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '6,000,000 DAR',
  //     raiseAmount: '$450,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.5,
  //   },
  //   currency: tokens.cake,
  //   token: tokens.dar,
  //   releaseBlockNumber: 12335455,
  //   campaignId: '511130000',
  //   articleUrl: 'https://polymm.finance/voting/proposal/QmeJenHcbY45eQxLRebzvpNk5qSCrj2wM1t4EAMHotFoJL',
  //   tokenOfferingPrice: 0.075,
  //   version: 2,
  // },
  // {
  //   id: 'dkt',
  //   address: '0x89ab9852155A794e371095d863aEAa2DF198067C',
  //   isActive: false,
  //   name: 'Duelist King (DKT)',
  //   poolBasic: {
  //     saleAmount: '75,000 DKT',
  //     raiseAmount: '$131,250',
  //     cakeToBurn: '$65,625',
  //     distributionRatio: 0.3,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '175,000 DKT',
  //     raiseAmount: '$306,250',
  //     cakeToBurn: '$153,125',
  //     distributionRatio: 0.7,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.dkt,
  //   releaseBlockNumber: 12130750,
  //   campaignId: '511120000',
  //   articleUrl: 'https://polymm.finance/voting/proposal/QmTRWdW9a65fAkyJy1wrAJRU548fNMAZhRUDrSxzMDLmwk',
  //   tokenOfferingPrice: 1.75,
  //   version: 2,
  // },
  // {
  //   id: 'kalmar',
  //   address: '0x1aFB32b76696CdF05593Ca3f3957AEFB23a220FB',
  //   isActive: false,
  //   name: 'Kalmar (KALM)',
  //   poolBasic: {
  //     saleAmount: '375,000 KALM',
  //     raiseAmount: '$750,000',
  //     cakeToBurn: '$375,000',
  //     distributionRatio: 0.3,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '875,000 KALM',
  //     raiseAmount: '$2,500,000',
  //     cakeToBurn: '$1,250,000',
  //     distributionRatio: 0.7,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.kalm,
  //   releaseBlockNumber: 7707736,
  //   campaignId: '511110000',
  //   articleUrl: 'https://pancakeswap.medium.com/kalmar-kalm-ifo-to-be-hosted-on-pancakeswap-4540059753e4',
  //   tokenOfferingPrice: 2.0,
  //   version: 2,
  // },
  // {
  //   id: 'hotcross',
  //   address: '0xb664cdbe385656F8c54031c0CB12Cea55b584b63',
  //   isActive: false,
  //   name: 'Hot Cross (HOTMATICSS)',
  //   poolBasic: {
  //     saleAmount: '15,000,000 HOTMATICSS',
  //     raiseAmount: '$750,000',
  //     cakeToBurn: '$375,000',
  //     distributionRatio: 0.3,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '35,000,000 HOTMATICSS',
  //     raiseAmount: '$1,750,000',
  //     cakeToBurn: '$875,000',
  //     distributionRatio: 0.7,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.hotcross,
  //   releaseBlockNumber: 7477900,
  //   campaignId: '511100000',
  //   articleUrl: 'https://pancakeswap.medium.com/hot-cross-hotcross-ifo-to-be-hosted-on-pancakeswap-10e70f1f6841',
  //   tokenOfferingPrice: 0.05,
  //   version: 2,
  // },
  // {
  //   id: 'horizon',
  //   address: '0x6137B571f7F1E44839ae10310a08be86D1A4D03B',
  //   isActive: false,
  //   name: 'Horizon Protocol (HZN)',
  //   poolBasic: {
  //     saleAmount: '3,000,000 HZN',
  //     raiseAmount: '$750,000',
  //     cakeToBurn: '$375,000',
  //     distributionRatio: 0.3,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '7,000,000 HZN',
  //     raiseAmount: '$1,750,000',
  //     cakeToBurn: '$875,000',
  //     distributionRatio: 0.7,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.hzn,
  //   releaseBlockNumber: 6581111,
  //   campaignId: '511090000',
  //   articleUrl: 'https://pancakeswap.medium.com/horizon-protocol-hzn-ifo-to-be-hosted-on-pancakeswap-51f79601c9d8',
  //   tokenOfferingPrice: 0.25,
  //   version: 2,
  // },
  // {
  //   id: 'belt',
  //   address: '0xc9FBedC033a1c479a6AD451ffE463025E92a1d38',
  //   isActive: false,
  //   name: 'Belt (BELT)',
  //   poolUnlimited: {
  //     saleAmount: '150,000 BELT',
  //     raiseAmount: '$3,000,000',
  //     cakeToBurn: '$1,500,000',
  //     distributionRatio: 1,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.belt,
  //   releaseBlockNumber: 5493919,
  //   campaignId: '511080000',
  //   articleUrl: 'https://pancakeswap.medium.com/belt-fi-belt-ifo-to-be-hosted-on-pancakeswap-353585117e32',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },
  // {
  //   id: 'watch',
  //   address: '0x55344b55C71Ad8834C397E6e08dF5195cF84fe6d',
  //   isActive: false,
  //   name: 'Yieldwatch (WATCH)',
  //   poolUnlimited: {
  //     saleAmount: '8,000,000 WATCH',
  //     raiseAmount: '$800,000',
  //     cakeToBurn: '$400,000',
  //     distributionRatio: 1,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.watch,
  //   releaseBlockNumber: 5294924,
  //   campaignId: '511070000',
  //   articleUrl: 'https://pancakeswap.medium.com/yieldwatch-watch-ifo-to-be-hosted-on-pancakeswap-d24301f17241',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },
  // {
  //   id: 'berry',
  //   address: '0x5d028cE3435B2bB9AceBfaC599EEbA1ccD63d7dd',
  //   isActive: false,
  //   name: 'Berry (BRY)',
  //   poolUnlimited: {
  //     saleAmount: '2,000,000 BRY',
  //     raiseAmount: '$1,000,000',
  //     cakeToBurn: '$500,000',
  //     distributionRatio: 1,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.bry,
  //   releaseBlockNumber: 4750968,
  //   campaignId: '511060000',
  //   articleUrl: 'https://pancakeswap.medium.com/berry-bry-ifo-to-be-hosted-on-pancakeswap-b4f9095e9cdb',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },
  // {
  //   id: 'soteria',
  //   address: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
  //   isActive: false,
  //   name: 'Soteria (wSOTE)',
  //   poolUnlimited: {
  //     saleAmount: '1,500,000 wSOTE',
  //     raiseAmount: '$525,000',
  //     cakeToBurn: '$262,500',
  //     distributionRatio: 1,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.wsote,
  //   releaseBlockNumber: 4086064,
  //   campaignId: '511050000',
  //   articleUrl: 'https://pancakeswap.medium.com/soteria-sota-ifo-to-be-hosted-on-pancakeswap-64b727c272ae',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },
  // {
  //   id: 'helmet',
  //   address: '0xa32509d760ee47Eb2Be96D338b5d69B5FBa4eFEB',
  //   isActive: false,
  //   name: 'Helmet.insure (Helmet)',
  //   poolUnlimited: {
  //     saleAmount: '10,000,000 Helmet',
  //     raiseAmount: '$1,000,000',
  //     cakeToBurn: '$500,000',
  //     distributionRatio: 1,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.helmet,
  //   releaseBlockNumber: 3771926,
  //   campaignId: '511040000',
  //   articleUrl: 'https://pancakeswap.medium.com/1-000-000-helmet-helmet-ifo-to-be-hosted-on-pancakeswap-3379a2a89a67',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },
  // {
  //   id: 'tenet',
  //   address: '0xB922aA19A2603A07C6C9ED6c236618C9bac51f06',
  //   isActive: false,
  //   name: 'Tenet (TEN)',
  //   poolUnlimited: {
  //     saleAmount: '1,000,000 TEN',
  //     raiseAmount: '$1,000,000',
  //     cakeToBurn: '$500,000',
  //     distributionRatio: 1,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.ten,
  //   releaseBlockNumber: 3483883,
  //   campaignId: '511030000',
  //   articleUrl: 'https://pancakeswap.medium.com/tenet-ten-ifo-to-be-hosted-on-pancakeswap-b7e1eb4cb272',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },
  // {
  //   id: 'ditto',
  //   address: '0x570c9eB19553526Fb35895a531928E19C7D20788',
  //   isActive: false,
  //   name: 'Ditto (DITTO)',
  //   poolUnlimited: {
  //     saleAmount: '700,000 DITTO',
  //     raiseAmount: '$630,000',
  //     cakeToBurn: '$315,000',
  //     distributionRatio: 1,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.ditto,
  //   releaseBlockNumber: 3279767,
  //   campaignId: '511020000',
  //   articleUrl: 'https://pancakeswap.medium.com/ditto-money-ditto-ifo-to-be-hosted-on-pancakeswap-342da3059a66',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },
  // {
  //   id: 'blink',
  //   address: '0x44a9Cc8463EC00937242b660BF65B10365d99baD',
  //   isActive: false,
  //   name: 'BLINk (BLK)',
  //   poolUnlimited: {
  //     saleAmount: '100,000,000 BLINK',
  //     raiseAmount: '$1,000,000',
  //     cakeToBurn: '$500,000',
  //     distributionRatio: 1,
  //   },
  //   currency: cakeBnbLpToken,
  //   token: tokens.blink,
  //   releaseBlockNumber: 3279767,
  //   campaignId: '511010000',
  //   articleUrl: 'https://medium.com/pancakeswap/1-000-000-ifo-blink-joins-pancakeswap-15841800bdd8',
  //   tokenOfferingPrice: null,
  //   version: 1,
  // },
]

export default ifos
